import { Controller } from 'stimulus'
import { Loader } from 'google-maps'

export default class extends Controller {
  static targets = []
  
  initialize() {
    if (typeof google === 'object' && typeof google.maps === 'object') {
        console.log('Loaded')
        this.initMap()
    } else {
        const key = 'AIzaSyAAQjKP3LggEnQ5GefQii-Zrv05SIVtlG8'
        let loader = new Loader(key)
        loader.load()
        .then((google) => {
            this.initMap('')
        })
    }
  }

  connect () {

  }

  initMap() {
        const el = document.querySelector('.map')
        console.log(el)
        const locations = document.querySelectorAll('.visit-locations-location')
        const mapOptions = {
            zoom: 14,
            center: new google.maps.LatLng(52.368346, 4.8933812),
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            streetViewControl: false,
            mapTypeControl: false
        }

        const map = new google.maps.Map(el, mapOptions)
        locations.forEach((location) => {
            const lat = location.querySelector('.visit-location-lat').innerHTML
            const long = location.querySelector('.visit-location-long').innerHTML
            const label = location.querySelector('.visit-location-label').innerHTML
            let marker = new google.maps.Marker({
                position: new google.maps.LatLng(parseFloat(lat), parseFloat(long)),
                map: map,
                label: label,
                title: ''
            })
        })
    }
}

// GoogleMapsLoader.load(function(google) {
// 	new google.maps.Map(el, options);
// });