const languages = {
    de: {
      title: 'Cookie-Richtlinie',
      content: 'Unsere Website verwendet Cookies, um zu analysieren, wie die Site verwendet wird, und um sicherzustellen, dass Ihre Benutzererfahrung bei nachfolgenden Besuchen konsistent ist.',
      accept: 'Zustimmen',
      learnMore: 'Mehr erfahren',
    },
    en: {
      title: 'Cookies policy',
      content: 'Our website uses cookies to analyse how the site is used and to ensure your experience is consistent between visits.',
      accept: 'Accept',
      learnMore: 'Learn more',
    },
    it: {
      title: 'Polizza cookie',
      content: 'Il nostro sito utilizza cookie per analizzare le tue abitudini e per assicurarsi che la tua esperienza sia consistente tra le visite.',
      accept: 'Accetta',
      learnMore: 'Altre informazioni',
    },
    fr: {
      title: 'Politiques d\'utilisation des cookies',
      content: 'Notre site web utilise des cookies pour analyser son utilisation et vous permettre ainsi d\'obtenir une expérience de qualité.',
      accept: 'Accepter',
      learnMore: 'En apprendre plus',
    },
    pt: {
      title: 'Política de Cookies',
      content: 'O nosso website utiliza Cookies para analisar a forma como o mesmo é utilizado e garantir uma experiência consistente e de qualidade para todos os visitantes.',
      accept: 'Aceitar',
      learnMore: 'Saber mais',
    },
    pl: {
      title: 'Polityka cookies',
      content: 'Nasza strona używa ciasteczek do analizy statystyk i zapewnienia takiego samego działania pomiędzi wizytami.',
      accept: 'Akceptuję',
      learnMore: 'Czytaj więcej',
    },
    ro: {
      title: 'Politica de utilizare Cookie-uri‎',
      content: 'Site-ul nostru utilizează module cookie și alte tehnologii similare pentru a optimiza funcţionalitatea si a îmbunătăţi experienţa de navigare.',
      accept: 'Acceptă',
      learnMore: 'Află mai multe',
    },
  };
  
  export default languages;