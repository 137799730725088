import languages from './languages';
import { createCookie, readCookie } from './cookies.js';

let COOKIE_BOX_INITIALIZED = false;

export default class CookieBox {
  constructor(params) {
    COOKIE_BOX_INITIALIZED = true;
    const userSettings = window.CookieBoxConfig || params || {};

    this.box = document.createElement('div');
    this.box.className = 'cookie-box';
    this.settings = {
      backgroundColor: userSettings.backgroundColor || '#3ea25f',
      textColor: userSettings.textColor || '#fff',
      language: userSettings.language || 'en',
      containerWidth: userSettings.containerWidth || 1140,
      url: userSettings.url || null,
      linkTarget: userSettings.linkTarget || '_blank',
      cookieKey: userSettings.cookieKey || 'cookie-box',
      cookieDomain: userSettings.cookieDomain || document.location.host,
      cookieExpireInDays: userSettings.cookieExpireInDays || 365,
      content: userSettings.content || {},
    };
    this.dictionary = languages[this.settings.language];
  }

  init() {
    if (!this.dictionary) {
      console.error(`${this.settings.language} language is not supported`);

      return;
    }

    if (readCookie(this.settings.cookieKey)) {
      return;
    }

    this.show();

    document.querySelector('.cookie-box__button').addEventListener('click', () => this.hide());
  }

  render() {
    const { settings } = this;

    return `
    <div>
      <div class="cookie-box__inner" style="">
        <div class="cookie-box__content">
            This website stores data such as cookies to enable important site functionality including analytics, targeting, and personalization.
            <a href="https://2020.sonicacts.com/privacy">Data Storage Policy</a>
        </div>
        <div class="cookie-box__buttons">
            <button class="cookie-box__button">
                Accept
            </button>
        </div>
      </div>
    </div>
    `;
  }

  show() {
    this.box.innerHTML = this.render();
    document.body.appendChild(this.box);
  }

  hide() {
    this.box.classList.add('hidden');
    createCookie(
      this.settings.cookieKey,
      true,
      this.settings.cookieExpireInDays,
      this.settings.cookieDomain,
    );

    setTimeout(() => {
      this.box.remove();
    }, 800);
  }
}

if (typeof window !== 'undefined') {
  const initBox = setInterval(() => {
    if (document.readyState === 'complete') {
      clearInterval(initBox);
      if (!COOKIE_BOX_INITIALIZED) {
        new CookieBox().init();
      }
    }
  }, 50);
}