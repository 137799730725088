import { Controller } from 'stimulus'
import Isotope from 'isotope-layout'

export default class extends Controller {

    static targets = [ 'container' ]
    initialize() {
    }

    connect () {
        this.iso = new Isotope(this.containerTarget, {
            itemSelector: '.programme-me-grid-item',
            layoutMode: 'masonry',
            getSortData: {
                // az: '[data-az]',
                // highlights: '[data-highlights]',
                // location: '[data-location]',
                // lastadded: 'original-order',         
            },
            sortBy: 'highlights' 
        })
    }
    
    disconnect() {
        this.iso.destroy()
    }
}
