import { Controller } from 'stimulus'
import Isotope from 'isotope-layout'

export default class extends Controller {

    static targets = [ 'container', 'filterEventType', 'sort', 'filterDate', 'location' ]
    initialize() {
        console.log('Init grid')
    }

    connect () {
        this.iso = new Isotope(this.containerTarget, {
            itemSelector: '.programme-grid-item',
            layoutMode: 'masonry',
            getSortData: {
                az: '[data-az]',
                highlights: '[data-highlights]',
                location: '[data-location]',
                lastadded: 'original-order',         
            },
            sortBy: 'highlights' 
        })
        this.filterData = { 
            date: '*', 
            event: '*',
            location: '*'
        }

        this.filterEventTypeTarget.childNodes.forEach( element => {
            element.addEventListener('click', () => {
                this.filter = {
                    location: this.filterData.location,
                    date: this.filterData.date,
                    event: element.getAttribute('data-filter').toLowerCase()
                }
                this.filterEventTypeTarget.querySelectorAll('.programme-grid-button--active').forEach(el => el.classList.remove('programme-grid-button--active'))
                element.classList.toggle('programme-grid-button--active')
            })        
        })

        // Date buttons
        this.filterDateTarget.childNodes.forEach( element => {
            element.addEventListener('click', () => {
                this.filter = { 
                    location: this.filterData.location,
                    date: element.getAttribute('data-filter').toLowerCase(), 
                    event: this.filterData.event
                }
                this.filterDateTarget.querySelectorAll('.programme-grid-button--active').forEach(el => el.classList.remove('programme-grid-button--active'))
                element.classList.toggle('programme-grid-button--active')
            })        
        })

        // Location buttons
        this.locationTarget.childNodes.forEach( element => {
            element.addEventListener('click', () => {
                this.filter = { 
                    location: element.getAttribute('data-filter').toLowerCase(), 
                    date: this.filterData.date, 
                    event: this.filterData.event 
                }
                this.locationTarget.querySelectorAll('.programme-grid-button--active').forEach(el => el.classList.remove('programme-grid-button--active'))
                element.classList.toggle('programme-grid-button--active')
            })        
        })
        
        // Sorting buttons
        this.sortTarget.childNodes.forEach( element => {
            element.addEventListener('click', () => {
                let sortOption = element.getAttribute('data-filter').toLowerCase()
                this.sort = sortOption
                this.sortTarget.querySelectorAll('.programme-grid-button--active').forEach(el => el.classList.remove('programme-grid-button--active'))
                element.classList.toggle('programme-grid-button--active')
            }) 
        })

    }
    
    disconnect() {
        this.iso.destroy()
    }

    set filter (value) {
        this.filterData = value
        this.iso.arrange({
            filter: ( gridItemElem ) =>  {
                let query = value

                let conditionEvent = gridItemElem.classList.contains(query.event)
                if (query.event === '*' ) conditionEvent = true 

                let conditionDate = gridItemElem.classList.contains(query.date)
                if (query.date === '*' ) conditionDate= true 

                let location = gridItemElem.getAttribute('data-location')
                let conditionLocation = location.includes(query.location)
                if (query.location === '*' ) conditionLocation = true 

                let condition = conditionEvent && conditionDate && conditionLocation
                return condition
            }
        })
    }
    set sort (value) {
        this.iso.arrange({ sortBy: value })
    }
}
