import { Controller } from 'stimulus'
import handorgel from 'handorgel'

export default class extends Controller {
  static targets = [ 'content' ]  

  connect () {
      console.log('error')
      this.accordion = new handorgel(this.element, {
        multiSelectable: true,
        collapsible: true,   
        ariaEnabled: true,
        keyboardInteraction: true,
        carouselFocus: true,
        initialOpenAttribute: 'data-open',
        initialOpenTransition: true,
        initialOpenTransitionDelay: 200,
        headerOpenClass: 'handorgel__header--open',
        contentOpenClass: 'handorgel__content--open',
        headerOpenedClass: 'handorgel__header--opened',
        contentOpenedClass: 'handorgel__content--opened',
        headerFocusClass: 'handorgel__header--focus',
        contentFocusClass: 'handorgel__content--focus',
        headerDisabledClass: 'handorgel__header--disabled',
        contentDisabledClass: 'handorgel__content--disabled',
        headerNoTransitionClass: 'handorgel__header--notransition',
        contentNoTransitionClass: 'handorgel__content--notransition'      
    })
  }

  disconnect() {
    this.accordion.destroy()
  }

}
