import styles from '../css/app.scss'
import Turbolinks from 'turbolinks'
import lazysizes from 'lazysizes'
import CookieBox from './cookies/';

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

Turbolinks.start()
const application = Application.start()
const context = require.context("./controllers", true, /\.js$/)
application.load(definitionsFromContext(context))

new CookieBox({backgroundColor: '#ec008c', url: '/privacy-policy'}).init();