import { Controller } from 'stimulus'

export default class extends Controller {
    static targets = []  

    initialize() {
        import(/* webpackChunkName: "popup" */ '../components/popUp.jsx').then(module => {
            this.popUp = module.default
        })

        this.elements = document.querySelectorAll('.tt-line-block-content')
        this.ticking = false;
        if (window.innerWidth < 750) {
            this.leftMargin = 10
        } else {
            this.leftMargin = document.querySelector('.tt-line-venue-name').offsetWidth + 30;
        }     
    }

    connect () {
    }

    disconnect () {
    }

    onClick(event) {
        event.preventDefault()

        if (this.popUp) {
            this.popUp({
            link: event.target.href,
            title: event.target.dataset.title,
            time: event.target.dataset.time, 
            artist: event.target.dataset.artist 
            })
        }
    }

    onScroll(event) {

  
        if (!this.ticking) {
          window.requestAnimationFrame(() => {
            let leftMargin = this.leftMargin
            this.elements.forEach( el => {
                let rect = el.getBoundingClientRect()
                let rectParent = el.parentElement.getBoundingClientRect()
    
                let offset = leftMargin - rectParent.left 
                let lenght = rect.right - rect.left 
    
                if ( rectParent.left < leftMargin && rectParent.right >= rectParent.left + offset + lenght + 20 ) {
                    //el.classList.add('marker')
                    el.style.transform = 'translateX(' + offset + 'px)'
                }    
            })
            this.ticking = false;
          });     
          this.ticking = true;
        }
    }
  }