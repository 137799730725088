import { Controller } from 'stimulus'

export default class extends Controller {

    initialize() {
    }

    connect () {
    }

    disconnect () {
    }

    clickHandlerClose (event) {
        event.preventDefault()
        // this.element.classList.add('popup--close')
        document.querySelector('.redirect-popup-container').remove()
    }

  }