import { Controller } from 'stimulus'
import Flickity from 'flickity'

export default class extends Controller {
    static targets = ['carousel']  

    initialize() {
      this.flkty = new Flickity( this.carouselTarget, {
        cellAlign: 'left',
        contain: true,
        groupCells: true,
        pageDots: true,
        wrapAround: true,
        prevNextButtons: false,
        autoPlay: 5000,
        selectedAttraction: 0.0045,
        friction: 0.1 
      })
    }

    connect () {
    }

    disconnect () {
      this.flkty.destroy()
    }

  }