import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'content' ]  

  connect () {
  }

  clickHandlerOpen () {
    this.contentTarget.classList.remove('js-menu-hiden')
    document.body.classList.toggle('js-body-noscroll')
  }

  clickHandlerClose () {
    this.contentTarget.classList.add('js-menu-hiden')
    document.body.classList.toggle('js-body-noscroll')
  }
}
